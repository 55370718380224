import React from 'react';
import { node, oneOfType, string, bool } from 'prop-types';
import styles from './LinkTag.module.scss';

const LinkTag = ({ href, className, children, underline, ...restProps }) => {
  const AnchorTag = (
    <a
      {...(href ? { href: href } : {})}
      className={`${className} ${underline ? styles.underline : ''}`}
      {...restProps}
      {...(!href && { role: 'button' })}
    >
      {children}
    </a>
  );
  return href ? <a href={href}>{AnchorTag}</a> : AnchorTag;
};

LinkTag.propTypes = {
  children: oneOfType([string, node]).isRequired,
  href: string,
  className: string,
  underline: bool,
};

LinkTag.defaultProps = {
  href: null,
  className: '',
  underline: false,
};

export default LinkTag;
