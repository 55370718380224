import React from 'react';
import { func, object, string } from 'prop-types';
import { Field } from 'react-final-form';
import styles from './InputField.module.scss';
import Input from '@CommonUI/input/Input';
import Typography from '@CommonUI/typography/Typography';

const InputComp = ({ restProps, ...inputProps }) => {
  const handleOnBlur = (e) => {
    restProps?.onBlur?.(e);
    restProps?.inputProps?.onBlur?.(e);
    inputProps?.onBlur?.(e);
  };

  return <Input {...restProps} {...inputProps} onBlur={handleOnBlur} />;
};

InputComp.propTypes = {
  restProps: object,
};

InputComp.defaultProps = {
  restProps: {},
};

/**
 * InputField Component
 * @param {string} name - Name for input field
 * @param {string} className - Class name to override styles
 * @param {function} validate - Validation functions
 * @param {function} parse - React Final Form prop to parse input values
 * @returns {*}
 * @constructor
 */
const InputField = ({ name, validate, className, parse, ...restProps }) => {
  return (
    <Field
      name={name}
      validate={validate}
      format={(value) => value?.trim()}
      formatOnBlur
      {...(parse && { parse: parse })}
    >
      {({ input, meta }) => (
        <div className={`${className} ${meta.touched && meta.error ? styles.error : ''}`}>
          <InputComp {...input} restProps={restProps} />
          {meta.touched && meta.error && (
            <Typography variant="label" theme="error">
              *{meta.error}
            </Typography>
          )}
        </div>
      )}
    </Field>
  );
};

InputField.propTypes = {
  className: string,
  name: string.isRequired,
  validate: func,
  parse: func,
};

InputField.defaultProps = {
  className: '',
  validate: null,
  parse: null,
};

export default InputField;
