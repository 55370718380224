// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n * Extra small devices (portrait phones, less than 576px)\n * No media query since mobile is default\n * 1. Small devices (landscape phones, 576px and up)\n * 2. Medium devices (tablets, 768px and up)\n * 3. Large devices (desktops, 992px and up)\n * 4. X-Large devices (large desktops, 1200px and up)\n * 5. XX-Large devices (larger desktops, 1400px and up)\n */\n.ContainerFluid-module--container-fluid {\n  width: 100%;\n  padding: 0 30px;\n  margin: 0 auto;\n}", "",{"version":3,"sources":["webpack://./src/assets/styles/Variables.scss","webpack://./src/commonUI/layout/containerFluid/ContainerFluid.module.scss"],"names":[],"mappings":"AAAA;;;;;;;;EAAA;ACMA;EACE,WAAA;EACA,eAAA;EACA,cAAA;AAIF","sourcesContent":["/**\n * Extra small devices (portrait phones, less than 576px)\n * No media query since mobile is default\n * 1. Small devices (landscape phones, 576px and up)\n * 2. Medium devices (tablets, 768px and up)\n * 3. Large devices (desktops, 992px and up)\n * 4. X-Large devices (large desktops, 1200px and up)\n * 5. XX-Large devices (larger desktops, 1400px and up)\n */\n$BREAK_POINTS: sm, md, lg, xl, xxl;\n$BREAK_POINTS_MAP: (\n  sm: 576,\n  md: 768,\n  lg: 992,\n  xl: 1200,\n  xxl: 1400,\n);\n\n// Font configuration\n$baseMobileFontSize: 14px;\n$baseDesktopFontSize: 16px;\n$baseLineHeight: 1.4;\n$baseFontWeight: 400;\n$baseLetterSpacing: 0.32px;\n$Roboto: \"Roboto\";\n\n//z-index\n$zindex-dropdown: 1000;\n$zindex-fixed: 1010;\n$zindex-overlay: 1020;\n$zindex-modal: 1030;\n$zindex-modal-content: 1031;\n$zindex-popover: 1040;\n$zindex-tooltip: 1050;\n","@import '@Assets/styles/Mixins';\n\n// As per the UX the container margin for both sides is 2*140px(280px)\n// Visually 240px looks matching with ux\n// Decrease margin by 60px for higher screens\n// Scales all device widths\n.container-fluid {\n  width: 100%;\n  padding: 0 30px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container-fluid": "ContainerFluid-module--container-fluid",
	"containerFluid": "ContainerFluid-module--container-fluid"
};
export default ___CSS_LOADER_EXPORT___;
