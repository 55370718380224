import { Col, Container, Row } from '@CommonUI/layout';
import React, { Fragment, useEffect, useState } from 'react';
import AppLayout from '../../app/appProvider/AppLayout';
import styles from './Register.module.scss';
import InquiryForm from '@Components/inquiryBox/inquiryForm/InquiryForm';
import OtpVerify from '@Components/inquiryBox/otpVerify/OtpVerify';
import Typography from '@CommonUI/typography/Typography';

const Register = () => {
  const [formSubmitSuccess, setFormSubmitSuccess] = useState('');

  return (
    <AppLayout>
      <Row justifyContent="center" alignItems="center" className={styles.homeV2Form}>
        {formSubmitSuccess ? (
          <Row
            className={styles.home}
            justifyContent="center"
            alignItem="center"
            alignContent="center"
            flexDirection="column"
          >
            <OtpVerify email={formSubmitSuccess} />
          </Row>
        ) : (
          <InquiryForm
            handleOnSubmitSuccess={(response) => {
              setFormSubmitSuccess(response.email);
            }}
          />
        )}
      </Row>
    </AppLayout>
  );
};

export default Register;
