import useBrowserLayoutEffect from './UseBrowserLayoutEffect';

const useBodyScrollLock = (lock = true) => {
  useBrowserLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = document.body.style['overflow-y'];

    // Prevent scrolling on mount
    if (lock) {
      document.body.style['overflow-y'] = 'hidden';
    }

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style['overflow-y'] = originalStyle;
    };
  }, [lock]);

  return null;
};

export default useBodyScrollLock;
