import React, { useState } from 'react';
import Card from '@CommonUI/card/Card';
import styles from './SideNav.module.scss';
import Icon from '@CommonUI/icon/Icon';

const navigationItems = [
  { id: 1, name: 'Home', path: '/' },
  { id: 3, name: 'Inquiries', path: '/contact' },
  { id: 4, name: 'Security', path: '/contact' },
  { id: 5, name: 'Payments & Subscriptions', path: '/contact' },
];

const SideNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <>
      <Card className={styles.sidenavListmenu}>
        {/* <button onClick={() => setIsOpen(!isOpen)}>Toggle Nav</button> */}
        <nav className={styles.sidenavBox}>
          <ul className={styles.sidenavUlbar}>
            {navigationItems.map((item) => (
              <li
                key={item.id}
                onClick={() => {
                  setActiveTab(item.id);
                }}
                className={`${styles.sidenavLink} ${
                  item.id === activeTab ? styles.sidenavLinkActive : ''
                }`}
              >
                <Icon />
                <a href="#" onClick={() => setActive(isActive)}>
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </Card>
    </>
  );
};

export default SideNav;
