import AppLayout from '../../app/appProvider/AppLayout';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from '@CommonUI/layout';
import Typography from '@CommonUI/typography/Typography';
import styles from './EventDetails.module.scss';
import Button from '@CommonUI/button/Button';
import { Carousel } from 'react-responsive-carousel';
import venueList from '../../constants/venuelist.json';
import { useLocation } from 'react-router-dom';

const universityList = [
  {
    name: 'Dublin City University',
    url: 'https://www.dcu.ie/',
    src: 'https://eis.bmi-systems.net/uploads/logos/skNMMS3iQfcLguKk.png',
    description:
      'Established in 1980, DCU Dublin City University (DCU) is one of the best young universities in the world as ranked by the QS Top 150 Under 50 and the Times Higher Education Top100 Under 50. We have a proud tradition of world-class academic excellence, research and innovation.',
  },
  {
    name: 'South East Technological University',
    url: 'https://www.setu.ie/',
    src: 'https://eis.bmi-systems.net/uploads/logos/JfPI8Xftgi3EunsK.png',
    description:
      'South East Technological University is the first technological university in south east Ireland. This gives us an exciting platform to establish our community as a centre for innovation, opportunity, and growth. Through exceptional learning and collaboration, we aim to transform the ambitions of learners, researchers, and businesses across the south east and beyond.',
  },
  {
    name: 'University College Dublin',
    url: 'https://www.ucd.ie/',
    src: 'https://eis.bmi-systems.net/uploads/logos/6I6J5E1CSj4GNvSA.jpg',
    description:
      "UCD is one of Europe's leading research-intensive universities; an environment where undergraduate education, masters and PhD training, research, innovation and community engagement form a dynamic spectrum of activity. UCD is also Ireland's most globally engaged university with over 30,000 students from over 139 countries                                ",
  },
  {
    name: '',
    url: '',
    src: 'https://eis.bmi-systems.net/uploads/logos/8hfBMCHT4VBl6XhP.jpg',
    description:
      "The Faculty of Medicine &amp; Health Sciences is home to Ireland's largest medical school and Schools of Pharmacy and Biomolecular Sciences, Physiotherapy, Nursing & Midwifery, Postgraduate Studies and the RCSI Graduate School of Healthcare Management. Our degrees and awards are fully aligned to the National Framework of Qualifications, from levels 7-10, in collaboration with the National University of Ireland.",
  },
];

const EventDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cityId = queryParams.get('id');

  const venueDetails = venueList.find((item) => item.id === cityId);

  return (
    <AppLayout>
      <Row className={styles.eventHeadContainer} flexDirection="column" rowGap={10}>
        <Container>
          <Row className={styles.eventHead} flexDirection="column" rowGap={10}>
            <Typography className={styles.eventHeadTitle} variant="h1">
              Study in Ireland Roadshow comes to India
            </Typography>
            <Typography variant="p">
              The Irish Government presents an opportunity for you to meet face-to-face with
              Ireland's best Universities and Colleges.
            </Typography>
            <Typography variant="p">
              Join us, and take your first step to study in Ireland!
            </Typography>
            <Button className={styles.eventHeadBtn}>
              <a href="/register"> Register here for free! </a>
            </Button>
          </Row>
        </Container>
      </Row>
      <Container>
        <Row rowGap={30}>
          <Row className={styles.eventPlace} justifyContent="center" rowGap={30}>
            <Row className={styles.eventPlaceLoc} rowGap={20}>
              <Row
                className={styles.eventPlaceLocData}
                columnGap={50}
                justifyContent="center"
                alignItems="flex-start"
                rowGap={20}
              >
                <Col md={6} className={styles.eventPlaceLocDevider}>
                  <Row justifyContent="flex-end" flexDirection="column">
                    <Typography className={`${styles.eventPlaceTitle}`} variant="h2">
                      {venueDetails?.city}
                    </Typography>
                    <Typography variant="h2" className={`${styles.eventPlaceTitle}`}>
                      {venueDetails?.date}
                    </Typography>
                    <Typography variant="p">{venueDetails?.time}</Typography>
                  </Row>
                </Col>
                <Col md={4}>
                  <Typography variant="h5">{venueDetails?.location}</Typography>
                  <Typography variant="p">{venueDetails?.address}</Typography>
                </Col>
              </Row>
              <Row
                className={styles.eventPlaceDetail}
                rowGap={20}
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
                alignItems="center"
              >
                <Typography
                  variant="h3"
                  className={`${styles.eventPlaceTitle} ${styles.eventPlaceH3}`}
                >
                  This is your opportunity to discover Ireland
                </Typography>
                <Button className={styles.eventHeadBtn}>
                  <a href="/">Would you like to attend the event in another city? </a>
                </Button>
                <Row rowGap={20}>
                  <Col md={6}>
                    <Typography variant="h4" className={styles.eventPlaceTitle}>
                      Talk privately face to face
                    </Typography>
                    <Row flexDirection="row" flexWrap="nowrap">
                      <svg
                        className={styles.eventPlaceSvg}
                        fill="#000000"
                        height="20px"
                        width="20px"
                        version="1.1"
                        id="Layer_1"
                        viewBox="0 0 512 512"
                        enable-background="new 0 0 512 512"
                      >
                        <path
                          d="M236.3,65.6c1.2,0,2.7,0.6,4,2.4c-5.8-8.3,6.5-4.7,7.2-12.6c7-5.2,4.5-8.6,6.5-8.8l2.2-1.8c-4.3,1.6-5.8,1.9-7,1.9
	c-0.3,0-0.6,0-0.9,0c-0.3,0-0.7,0-1.1,0c-1.5,0-4,0.3-9.9,2.1c-13.5,1-23.2,15.6-27.8,16c-4.7,2.5-4.5,3-3.4,3
	c0.3,0,0.7-0.1,1.1-0.1c0.4,0,0.7-0.1,1-0.1c1.1,0,0.8,0.5-5.6,3.5c1.5,0.4-11.2,6-11.3,14.8c-0.4,3.4,1,5.7,3.3,5.7
	c2,0,4.5-1.5,7.2-5.3c9-10.4,28-11.4,36.2-17.7C232.9,68.2,233.9,65.6,236.3,65.6z M190.6,69.1c-0.9,0,0.3-1.1,1.6-2.2
	c1.4-1.1,3-2.2,3-2.2c0,0,0,0-0.1,0.1c5.4-2.1,2.1-2.6,5-4c-0.1,0-0.1,0-0.2,0c-0.6,0,0.5-0.8,1.5-1.7c1-0.8,1.9-1.7,1-1.7
	c-0.5,0-1.8,0.3-4,1.2c0.7-1,3.4-1.7,7.2-3.8c-5.5,1.8-9.8,2.9-10.3,2.9c-0.4,0,1.9-0.8,8.8-2.9c0.3,0,0.6,0.1,0.8,0.1
	c0.7,0,1-0.2,0.9-0.4c-0.1-0.2-0.5-0.3-1.2-0.3h0c-9.9,0.8-17.3,7-15.1,7.8c1.3-0.4,2.2-0.6,2.8-0.6c1.3,0,0.7,1.1-1.9,2.7
	c1.2,2.8-20,10-22.4,12.6c1.2-0.7,2-0.9,2.4-0.9c1.6,0-0.5,3.1-2.6,4.6c1.3,1.2,2.3,1.6,3.1,1.6c2.1,0,3-2.9,4.8-3.8
	c0.4,0.8,0.8,1.2,1.3,1.2c1.7,0,3.8-4.2,5.5-6.4c0.5,0.5,1.1,0.9,1.9,0.9c1.6,0,4-1.3,7.8-5C191.4,69,190.8,69.1,190.6,69.1z
	 M167.6,87.5c-3.6,2.2-5.5,3.1-6.3,3.1c-1.5,0,0.5-2.8,2.3-5.7c1.8-2.8,3.4-5.6,1.1-5.6c-1.1,0-3.1,0.6-6.3,2.2
	c-8.7,5.8-23.4,21.8-29.9,21.8c-0.2,0-0.4,0-0.6,0c10.5-7.7,7.4-22.8,22.1-26.7c14.5-8.3,24.4-5.7,37.3-15.2c-3.8,1.9-9.4,4-10.7,4
	c-0.8,0,0.4-1,5.6-3.5c-0.5,0.1-0.9,0.2-1.2,0.2c-3.5,0,23-9.5,24.3-9.7C194.4,53.9,176.8,63,174.4,63c-0.3,0-0.3-0.1-0.3-0.3
	c0.4-0.3,0.4-0.5,0-0.5c-1.3,0-6.7,1.8-12.2,3.6c-5.4,1.8-10.9,3.7-12.1,3.7c-0.4,0-0.4-0.2,0.2-0.6c-47.2,26.8-86.6,72-98.9,125.6
	c5.1,11.7,1.6,34,10,40.5c9.6,8.1-8.2,31.6-3.5,46.1c4.8,26.2,25.5,44.1,27.7,70.9c3.8,18.4,17.7,40.9,23.5,52.6
	c4.4,4.4,16.5,17.1,18.6,17.1c0.8,0,0.3-1.9-2.9-7c-2-5.7-13.6-20.6-8-20.6c0.3,0,0.6,0,1,0.1c-6.9-7,15.3-5.1-0.2-14.6
	c-1.8-2.2-2.2-2.9-1.8-2.9c0.5,0,1.9,0.8,3.5,1.6c1.7,0.8,3.7,1.6,5.3,1.6c2.5,0,4.2-1.7,3.2-7.9c0.4,0.2,0.8,0.2,1.1,0.2
	c2.6,0,1.7-5.1,1.9-10.2c0.2-5.1,1.5-10.2,8.5-10.2c0.8,0,1.7,0.1,2.6,0.2c16.2-8.8,2.1-33.1,20.7-42c-0.7-20.2-27.8-21.2-38-29.4
	c-1.7,1-3.3,1.3-4.9,1.3c-1.8,0-3.4-0.4-4.8-0.9c-1.4-0.4-2.7-0.9-3.6-0.9c-0.2,0-0.4,0-0.6,0.1c17.4-4.6,4.7-28.8-9.7-28.8
	c-0.3,0-0.7,0-1,0c-1.1-10.5-6.5-6.5-7.1-15.2c-0.9,0.5-1.8,0.7-2.7,0.7c-2.4,0-4.6-1.7-6.5-3.3c-1.9-1.7-3.5-3.3-4.9-3.3
	c-1.1,0-2.1,1.1-3,4.3c1-4.9,0.7-6.5-0.3-6.5c-1,0-2.8,1.7-4.8,3.4c-1.9,1.7-4.1,3.4-5.8,3.4c-0.2,0-0.3,0-0.5,0
	c-11.7-8.3,0.1-23-6.1-34.3c4-4.3,5.6-11.6,3.8-11.6c-0.9,0-2.7,1.8-5.5,6.7c-4-10.2,6.6-33.3,15.6-34.6c0.3,0,0.5-0.1,0.8-0.1
	c1.6,0,3.2,0.8,4.6,2.5c0.8,4.7-1.2,14.6-0.4,14.6c0.4,0,1.2-2,3.2-7.2c2.6-11.9,20.8-20.5,22.7-27.8c0.1,0.1,0.1,0.1,0.2,0.1
	c2.1,0,15.6-11.9,21.5-13.2c2.3-2.3,3.9-3.1,5.1-3.1c1.3,0,2,1,2.5,1.9c0.5,1,0.9,1.9,1.4,1.9c0.4,0,0.9-0.6,1.8-2.3
	c-3.1-6.1,4.9-11.9,2.7-11.9c-0.8,0-3.2,0.8-8.1,2.8c-0.8,0.4-1.2,0.5-1.4,0.5c-0.6,0,1.8-1.6,5.3-3.2c3.5-1.6,7.9-3.2,11.6-3.2
	c1.8,0,3.4,0.4,4.6,1.4c15.5-3.7,7-8.8,7.8-8.8c0,0,0.1,0,0.2,0C162.4,103.4,161.1,93.6,167.6,87.5z M98.3,128.4
	c-0.2,0,0.7-1.3,3.7-5c6.2-4.6,11.2-7.7,5.5-10.7c2.5-1.1,4.9-2.4,7.2-3.8c-0.1,4.9-4.2,16.8-6.4,16.8c-0.5,0-1-0.7-1.2-2.2
	c1.3-2.3,1.4-3.2,0.9-3.2c-0.8,0-3.1,2-5.3,4.1C100.5,126.3,98.5,128.4,98.3,128.4z M104,131.1c-2.7,0-2.2-1.7,6.1-3.4
	c3.6-0.5,0.8-0.3,5.1-1.3C111,129.8,106.3,131.1,104,131.1z M259.5,121c1.2-0.2,2.1-0.2,2.8-0.2c2.9,0,1.6,1.2-0.1,2.4
	c-1.7,1.2-3.8,2.4-2.6,2.4c0.7,0,2.6-0.4,6.5-1.5c19.7-1.4-7.7-18.1-3.3-23.7l-1.2-0.4C253.5,110.3,267,110.5,259.5,121z
	 M251.3,121.4c1.4,0,3.2-1.6,5.1-6.1c2.6-3.1,1.5-3.5-1.3-5C247.4,112.3,248.1,121.4,251.3,121.4z M320.3,153.1
	c-0.3,0-0.5,0.1-0.7,0.2c0.8,0.2,1.6,0.4,2.4,0.4C321.2,153.3,320.7,153.1,320.3,153.1z M454.6,178c1.7,3.7,2.6,5.2,2.7,5.2
	c0.7,0-12.6-32.4-18.1-38.2c-28.7-47.2-75.9-83.3-129.7-96.8h-0.1c-1.4,0,0.2,0.7,2.1,1.4c1.9,0.7,4.2,1.4,4.2,1.4
	c0,0-1.5-0.5-6.1-1.8c-5.1-1.5-10.3-2.6-15.6-3c-0.9,0.3,21.9,9.3,37.2,14c-5.1-1.6-10.4-3-11.7-3c-0.9,0,0,0.6,3.7,2.2
	c-4.5-1.3-6.4-1.9-6.6-1.9c-0.3,0,6,2.1,11.9,4.2c6,2.1,11.7,4.2,10.1,4.2c-0.4,0-1.5-0.2-3.2-0.5c5.1,2.8,6.9,3.9,6.6,3.9
	c-0.5,0-6.8-3-13.3-6c-6.4-3-12.9-6-13.8-6c-0.5,0,0.8,1,5.2,3.6c6,2.6,12,5.2,10.3,5.2c-0.8,0-3.1-0.5-7.6-1.8
	c10,5.1-7.1,3.1-1.3,10c-3.2-3-4.5-4-4.8-4s0.5,1.2,1.3,2.4c0.8,1.2,1.4,2.4,0.7,2.4c-0.1,0-0.1,0-0.2,0c7,7.6-4.9,0.8,3.6,7.6
	c-6.4-2.9-17.3-7.7-12.4-7.7c1.2,0,3.5,0.3,7.1,1.1c-6.5-7.6-24.9-7.9-25.3-8.8c-4.6,0.3-4.7,3.8-3.9,10.2
	c-0.1,10.1-10,10.3-8.7,16.1c0-0.1,0.1-0.1,0.1-0.1c0.2,0,0.3,2,0.9,4.1c0.7,2,1.9,4.1,4.5,4.1c1.4,0,3.2-0.6,5.4-2.1
	c3.1,5.6,5.9,7.6,8.1,7.6c3.3,0,5.3-4.5,5.1-8.6c-3.6-2-8.3-18.6-3.1-18.6c0.8,0,1.8,0.4,3.1,1.3c-8.1,19.9,32,6.6,9.3,15.7
	c5.1,6.7-3.2,6.4-0.8,14.5c-3.9,1.1-8.2,2.1-12,2.1c-5.6,0-9.9-2.4-9.4-10.8c-7,3.7,7,16.2-6.9,16.2h-0.8
	c-6.7,12.3-31.8,8.1-12.1,21.1c1.1,5.7-2.5,6.7-7.1,6.7c-1.2,0-2.5-0.1-3.8-0.2c-1.3-0.1-2.6-0.1-3.8-0.1c-5.2,0-8.9,1.3-5.7,9.4
	c-2.3,8.4,3.1,12.1,9.7,12.1c8,0,17.8-5.2,18.5-13.8c4.3-6.5,9.8-9.2,15.5-9.2c9,0,18.5,6.8,24.7,15.2c0.5,0.8,0.9,1.1,1.1,1.1
	c0.5,0,0-2-0.2-4c-0.2-1.6-0.2-3.2,0.4-3.7c-8.9-2.5-15.7-13.3-12.7-13.3c1,0,3.1,1.2,6.5,4.4c0.2,0,0.4,0,0.5,0
	c5.6,0,10,4.4,13.4,8.8c3.5,4.4,6.1,8.8,8.4,8.8c0.9,0,1.8-0.8,2.6-2.6c-0.4-0.6-1.4-2.3-1.9-4c-0.6-2.1-0.6-4.2,2.1-4.2
	c1.1,0,2.6,0.4,4.7,1.2c6.8-1.8-4.3-19.5,4.7-19.5c0.9,0,2,0.2,3.4,0.6c-0.1,3.1,1.3,4.2,2.6,4.2c2.6,0,5.2-3.7-1.1-4.8
	c2.4-2.3,3.8-3.2,4.8-3.2c3.5,0,0.7,11.6,12.8,11.6c4.1,9.7-35.6,0.7-22.9,19.3c2.9,2.1,6.5,2.5,10,2.5c1.1,0,2.1,0,3.1-0.1
	c1,0,2-0.1,2.9-0.1c6.2,0,10.6,1.5,7.9,14.3c-3.3,2.4-7,3.2-10.8,3.2c-3.4,0-6.8-0.6-10.1-1.2c-3.3-0.6-6.5-1.2-9.4-1.2
	c-4.5,0-8.1,1.5-10.3,6.7c-11.7-4.8-27.1-5-25.6-19.5c-20.4,2.9-41.7,1.4-56.7,13.9c-0.9,18.3-32.3,24.2-24.7,45
	c-9.1,19.2,11,46.1,31.5,46.1c0.7,0,1.5,0,2.2-0.1c11.2-0.1,22.5-5.1,32.4-5.1c5.1,0,9.7,1.3,13.7,5.3c0.9-0.1,1.7-0.2,2.4-0.2
	c15.3,0-4.9,24.3,13.4,28.4c16.6,17.2-10.2,34.9,2.9,51.4c1.4,12.9,7.8,23.7,7.8,36.3c1.7,0.2,3.3,0.3,5,0.3
	c21.1,0,34.3-18.4,46.7-32.3c-3.8-20.8,29.5-24.9,18.1-48.2c-5.3-25.5,25.1-40.6,23.4-66.1c0.3-3.6-0.7-4.7-2.2-4.7
	c-1.5,0-3.7,1.1-5.8,2.1c-2.2,1.1-4.6,2.1-6.5,2.1c-2.5,0-4.4-1.7-4.6-7.3c-14.7-13.7-21.4-32.3-35.1-47.4
	c17.8,8.6,27.9,28,36.1,45.6c0.7,0.2,1.4,0.3,2.2,0.3c14.5,0,34.7-34.7,12.7-43.1c-1.2,3.3-2.8,4.5-4.7,4.5c-3.3,0-7.1-4-9.8-7.9
	c-2.7-4-4.1-8-2.6-8c1,0,3.2,1.6,7,5.8c4.3,2.7,8.5,3.4,12.9,3.4c4.3,0,8.7-0.7,13.3-1.1c5.8,2.3,8.3,9.7,10.1,9.7
	c0.3,0,0.7-0.3,1-1c4.6,11,9.9,30.6,14.7,35.9C459.5,212.3,458.9,194.5,454.6,178z M398.8,154.9c-11.3-2.9-15-16.9-23.9-25
	c-0.1-2.2,3.2-1.8,2.4-5.5c12,3.7,8.1,7.4,7,8.1C388.7,139.8,401,144.8,398.8,154.9z M243.5,78.8c-10.9,3.2-12.4-0.3-10.5,6.4
	c0.8,0.6,2.3,0.8,3.8,0.8C242.1,86.1,249.3,83,243.5,78.8z M403.6,354.3c14.3-12.1,16.1-26.5,16.8-41.1
	C414,325.7,395,339.5,403.6,354.3z M338.5,159.3c-0.1,0.1-0.1,0.1-0.1,0.2c0.1,0.2,0.2,0.2,0.2,0.2
	C338.6,159.7,338.6,159.6,338.5,159.3z M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256c141.4,0,256-114.6,256-256S397.4,0,256,0z
	 M256,492.3C125.5,492.3,19.7,386.5,19.7,256S125.5,19.7,256,19.7S492.3,125.5,492.3,256S386.5,492.3,256,492.3z"
                        />
                      </svg>
                      <Typography variant="p">
                        Meet privately with the best universities and colleges in Ireland.
                      </Typography>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Typography variant="h4" className={styles.eventPlaceTitle}>
                      It's all about you
                    </Typography>
                    <Row flexDirection="row" flexWrap="nowrap">
                      <svg
                        className={styles.eventPlaceSvg}
                        fill="#000000"
                        version="1.1"
                        id="Layer_1"
                        width="20px"
                        height="20px"
                        viewBox="0 0 512 512"
                        enable-background="new 0 0 512 512"
                      >
                        <g>
                          <path
                            d="M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256S397.391,0,256,0z M256,472
		c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z"
                          />
                          <polygon points="240,234.656 246.234,320 265.781,320 272,233.875 272,128 240,128 	" />
                          <rect x="240" y="352" width="32" height="32" />
                        </g>
                      </svg>
                      <Typography variant="p">
                        Ask all your questions and learn how you can invest in your future by
                        studying in Ireland.
                      </Typography>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Typography variant="h4" className={styles.eventPlaceTitle}>
                      Meet with the Irish Government
                    </Typography>
                    <Row flexDirection="row" flexWrap="nowrap">
                      <svg
                        className={styles.eventPlaceSvg}
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M8 12H8.01M12 12H12.01M16 12H16.01M21.0039 12C21.0039 16.9706 16.9745 21 12.0039 21C9.9675 21 3.00463 21 3.00463 21C3.00463 21 4.56382 17.2561 3.93982 16.0008C3.34076 14.7956 3.00391 13.4372 3.00391 12C3.00391 7.02944 7.03334 3 12.0039 3C16.9745 3 21.0039 7.02944 21.0039 12Z"
                          stroke="#000000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <Typography variant="p">
                        Learn the application process, visas and post-study work rights.
                      </Typography>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Typography variant="h4" className={styles.eventPlaceTitle}>
                      Choose from 1000's of courses
                    </Typography>
                    <Row flexDirection="row" flexWrap="nowrap">
                      <svg width="35px" height="35px" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
                          stroke="#000000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <Typography variant="p">
                        Undergraduate or Postgraduate... no matter what course you are looking for,
                        you will find it at this event.
                      </Typography>
                    </Row>
                  </Col>
                </Row>
              </Row>
            </Row>
          </Row>
          <Row className={styles.eventUniv} justifyContent="center" flexDirection="column">
            <Typography variant="h3" className={styles.eventPlaceTitle}>
              Which institutions will be at the event?
            </Typography>
            <Row>
              <Col md={3}></Col>
              <Col md={6}>
                <Carousel showArrows={true}>
                  {universityList?.map((item) => (
                    <Row justifyContent="center">
                      <Col md={6}>
                        <Row>
                          <a href={item?.url}>
                            <img className={styles.eventUnivImg} src={item?.src} />
                            <p>{item?.description}</p>
                          </a>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                </Carousel>
              </Col>
              <Col md={3}></Col>
            </Row>
          </Row>
          <Row flexDirection="column" rowGap={20}>
            <Typography className={styles.eventPlaceTitle} variant="h4">
              Visa Sessions by Embassy of Ireland
            </Typography>
            <Typography className={styles.eventVenueDesc} variant="p">
              Arrive early and join an exclusive seminar on the dos and don’ts of the student visa
              application process by the Irish Visa Office.
            </Typography>
            <Typography className={styles.eventPlaceTitle} variant="h2">
              See times below for each city
            </Typography>
            {venueList.map((item) => (
              <Row className={styles.eventVenueItem}>
                <Col xs={3}>
                  <Row flexDirection="column">
                    <Typography variant="p">{item?.time}</Typography>
                  </Row>
                </Col>
                <Col>
                  <Typography className={styles.eventVenueItemCity} variant="h3">
                    {item?.city}
                  </Typography>
                  <Row
                    flexDirection="row"
                    justifyContent="left"
                    alignContent="flex-top"
                    alignItems="flex-top"
                  >
                    <svg
                      fill="#7ab800"
                      version="1.1"
                      id="Capa_1"
                      width="16px"
                      height="16px"
                      viewBox="0 0 395.71 395.71"
                    >
                      <g>
                        <path
                          d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
		c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
		C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
		c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"
                        />
                      </g>
                    </svg>
                    <Col>
                      <Typography variant="h5">{item.location}</Typography>
                      <Typography className={styles.homeV2Address} variant="p">
                        {item.address}
                      </Typography>
                      <Typography variant="p">{item?.date}</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </Row>
          <Row rowGap={30}>
            <Typography className={styles.eventPlaceTitle} variant="h3">
              Official Govt of Ireland Event
            </Typography>
            <Row>
              <a href="https://www.educationinireland.com/">
                <img src="https://india.educationinireland.live/wp-content/uploads/sites/8/2023/02/eduireland-1.jpg" />
              </a>
            </Row>
          </Row>
        </Row>
      </Container>
    </AppLayout>
  );
};

export default EventDetails;
