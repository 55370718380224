import React from "react";
import { Provider } from "react-redux";
import "./assets/styles/Global.scss";
import { Store } from "./redux/Store";
import RouterProvider from "./router";

export default function App() {
  return (
    <Provider store={Store}>
      <RouterProvider />
    </Provider>
  );
}
