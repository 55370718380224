import Card from '@CommonUI/card/Card';
import { Col, Container, Row } from '@CommonUI/layout';
import React, { Fragment, useEffect } from 'react';
import AppLayout from '../../app/appProvider/AppLayout';
import styles from './Home.module.scss';
import InquiryForm from '@Components/inquiryBox/inquiryForm/InquiryForm';
import { useState } from 'react';
import Typography from '@CommonUI/typography/Typography';
import card1 from '@Assets/images/card1.jpg';
import card2 from '@Assets/images/card2.jpg';
import card3 from '@Assets/images/card3.jpg';
import { Link } from 'react-router-dom';
import OtpVerify from '@Components/inquiryBox/otpVerify/OtpVerify';
import venueList from '../../constants/venuelist.json';
import Button from '@CommonUI/button/Button';

const HomeV2 = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const universityList2 = [
    { name: 'Atlantic Technological University', url: 'https://www.atu.ie/' },
    { name: 'Dublin Business School', url: 'https://www.dbs.ie/' },
    { name: 'University of Galway', url: 'https://www.universityofgalway.ie/' },

    {
      name: 'Technological University Dublin',
      url: 'https://www.tudublin.ie/',
    },
    {
      name: 'Technological University of the Shannon: Midlands Midwest',
      url: 'https://www.ait.ie/',
    },
    { name: 'Trinity College Dublin', url: 'https://www.tcd.ie/' },
    { name: 'Dundalk Institute of Technology', url: 'https://www.dkit.ie/' },
    { name: 'Griffith College', url: 'https://www.griffith.ie/' },
    { name: 'Maynooth University', url: 'https://www.maynoothuniversity.ie/' },
    {
      name: 'Munster Technological University',
      url: 'Munster Technological University',
    },
    { name: 'National College of Ireland', url: 'https://www.ncirl.ie/' },
    { name: 'University College Cork', url: 'https://www.ucc.ie/' },

    { name: 'University of Limerick', url: 'https://www.ul.ie/' },
  ];

  return (
    <AppLayout>
      <Container className={styles.homeV2}>
        <Row
          className={styles.homeV2Head}
          justifyContent="center"
          alignContent="center"
          flexDirection="column"
          textAlign="center"
        >
          {/* <Typography className={styles.venueContentTitle} variant="h1">
              <span className={styles.venueContentTitleCountry}>Ireland</span> Edu Fair 2023
            </Typography>
           */}
          <Typography className={styles.venueContentTitle} variant="h1">
            IRELAND COMES TO INDIA
          </Typography>
          <Typography variant="p">
            An Official Government of Ireland event to help you find your perfect course.
          </Typography>
          <Typography variant="p">
            Choose your nearest city, get more information and register to attend for free.
          </Typography>
        </Row>

        <Row justifyContent="center" rowGap={20}>
          {venueList.map((item) => (
            <Col className={styles.homeV2CardCol} md={5}>
              <Card className={styles.homeV2Card}>
                <Row flexDirection="coulmn" rowGap={5}>
                  <Typography className={styles.homeV2City} variant="h3">
                    {item.city}
                  </Typography>
                  <Row
                    flexDirection="row"
                    justifyContent="left"
                    alignContent="center"
                    alignItems="center"
                  >
                    <svg
                      className={styles.homeV2Svg}
                      width="20px"
                      height="20px"
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 122.88 122.89"
                    >
                      <path d="M81.61,4.73C81.61,2.12,84.19,0,87.38,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73ZM66.11,105.66c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1ZM15.85,68.94c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1Zm25.14-10.1H107c.8,0,.8,10.1,0,10.1H91.25c-.8,0-.8-10.1,0-10.1ZM15.85,87.3c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1ZM41,87.3c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1Zm25.14,0c-.8,0-.8-10.1,0-10.1H107c.8,0,.8,10.1,0,10.1Zm-75.4,18.36c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1ZM29.61,4.73C29.61,2.12,32.19,0,35.38,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73ZM6.4,43.47H116.47v-22a3,3,0,0,0-.86-2.07,2.92,2.92,0,0,0-2.07-.86H103a3.2,3.2,0,0,1,0-6.4h10.55a9.36,9.36,0,0,1,9.33,9.33v92.09a9.36,9.36,0,0,1-9.33,9.33H9.33A9.36,9.36,0,0,1,0,113.55V21.47a9.36,9.36,0,0,1,9.33-9.33H20.6a3.2,3.2,0,1,1,0,6.4H9.33a3,3,0,0,0-2.07.86,2.92,2.92,0,0,0-.86,2.07v22Zm110.08,6.41H6.4v63.67a3,3,0,0,0,.86,2.07,2.92,2.92,0,0,0,2.07.86H113.55a3,3,0,0,0,2.07-.86,2.92,2.92,0,0,0,.86-2.07V49.88ZM50.43,18.54a3.2,3.2,0,0,1,0-6.4H71.92a3.2,3.2,0,1,1,0,6.4Z" />
                    </svg>
                    <Typography variant="p">Date: {item.date}</Typography>
                  </Row>
                  <Row
                    flexDirection="row"
                    justifyContent="left"
                    alignContent="center"
                    alignItems="center"
                  >
                    <svg
                      className={styles.homeV2Svg}
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                        stroke="#000000"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 6V12"
                        stroke="#000000"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.24 16.24L12 12"
                        stroke="#000000"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <Typography variant="p">Time: {item.time}</Typography>
                  </Row>
                  <Row
                    flexDirection="row"
                    justifyContent="left"
                    alignContent="flex-top"
                    alignItems="flex-top"
                  >
                    <svg
                      className={styles.homeV2Svg}
                      fill="#000000"
                      version="1.1"
                      id="Capa_1"
                      width="20px"
                      height="20px"
                      viewBox="0 0 395.71 395.71"
                    >
                      <g>
                        <path
                          d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
		c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
		C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
		c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"
                        />
                      </g>
                    </svg>
                    <Col>
                      <Typography variant="h5">{item.location}</Typography>
                      <Typography className={styles.homeV2Address} variant="p">
                        {item.address}
                      </Typography>
                    </Col>
                  </Row>

                  {!isMobile && (
                    <Typography className={styles.homeV2CardBadge}>{item?.date}</Typography>
                  )}
                </Row>
                <Row>
                  <Button className={styles.homeV2CardBtn}>
                    <a href={`/event?city=${item?.city}&id=${item?.id}`}>
                      LEARN MORE AND REGISTER NOW
                    </a>
                  </Button>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </AppLayout>
  );
};

export default HomeV2;
