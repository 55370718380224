// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Icon-module--icon {\n  vertical-align: middle;\n}\n.Icon-module--icon-link {\n  cursor: pointer;\n}\n.Icon-module--icon-disabled {\n  opacity: 0.4;\n  cursor: default;\n  pointer-events: none;\n}", "",{"version":3,"sources":["webpack://./src/commonUI/icon/Icon.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,YAAA;EACA,eAAA;EACA,oBAAA;AAAJ","sourcesContent":[".icon {\n  vertical-align: middle;\n\n  &-link {\n    cursor: pointer;\n  }\n\n  &-disabled {\n    opacity: 0.4;\n    cursor: default;\n    pointer-events: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "Icon-module--icon",
	"icon-link": "Icon-module--icon-link",
	"iconLink": "Icon-module--icon-link",
	"icon-disabled": "Icon-module--icon-disabled",
	"iconDisabled": "Icon-module--icon-disabled"
};
export default ___CSS_LOADER_EXPORT___;
