import { useState } from 'react';

const useLoginModal = () => {
  const [show, setShow] = useState(false);

  const showLoginModal = () => setShow(!show);
  const hideLoginModal = () => setShow(!show);

  return {
    showLoginModal,
    hideLoginModal,
    isLoginModalActive: show,
  };
};

export default useLoginModal;
