import React from 'react';
import { node, oneOfType, string } from 'prop-types';
import styles from './ContainerFluid.module.scss';

/**
 * ContainerFluid Component
 * @param {any} children - Child node
 * @param {string} className - Custom Class to override styles
 * @param {string} id - id to place on row
 * @returns {*}
 */
const ContainerFluid = (props) => {
  const { children: children, className: className, id: id } = props;

  return (
    <div className={`${styles.containerFluid} ${className}`} id={id}>
      {children}
    </div>
  );
};

ContainerFluid.propTypes = {
  children: oneOfType([string, node]),
  className: string,
  id: string,
};

ContainerFluid.defaultProps = {
  className: '',
  children: null,
  id: null,
};

export default ContainerFluid;
