import React, { useEffect, useState } from 'react';
import { useTable, useSortBy } from 'react-table';

const Leads = () => {
  const [inquiryList, setInquiryList] = useState([]);
  const restEndpoint = 'https://test-4z7lafv5vq-as.a.run.app/api/';

  const getInquiryList = async () => {
    try {
      const response = await fetch(`${restEndpoint}inquiry`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInquiryList().then((data) => setInquiryList(data));
  }, []);

  const data = React.useMemo(() => inquiryList, [inquiryList]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone Number',
        accessor: 'phone',
      },
      {
        Header: 'Education',
        accessor: 'education',
      },
      {
        Header: 'Prefered Courses',
        accessor: 'preferedCource',
      },
      {
        Header: 'IELTS Done?',
        accessor: 'ieltsDone',
      },
      {
        Header: 'IELTS Score?',
        accessor: 'ieltsScore',
      },
      {
        Header: 'IELTS Exam Date?',
        accessor: 'examDate',
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  borderBottom: 'solid 3px red',
                  background: 'aliceblue',
                  color: 'black',
                  fontWeight: 'bold',
                }}
              >
                {column.render('Header')}
                <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: '10px',
                      border: 'solid 1px gray',
                      background: 'papayawhip',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Leads;
