import React, { Fragment } from 'react';
import { useState } from 'react';
import { Form, Field } from 'react-final-form';
import Button from '@CommonUI/button/Button';
import { Row } from '@CommonUI/layout';
import InputField from '@Components/formFields/inputFields/InputField';
import styles from './OtpVerify.module.scss';
import useSnackbar from '@Hooks/UseSnackbar';
import Typography from '@CommonUI/typography/Typography';

const OtpVerify = ({ className, handleOnSubmitSuccess, email }) => {
  const [isOtpVerified, setOtpVerified] = useState(false);
  const classes = [className, styles.inquiry].join(' ').trim();
  const { showSnackbarError } = useSnackbar();

  const restEndpoint = 'http://localhost:5000/api/';

  const formSubmitData = async (data) => {
    const formData = {
      otp: data?.otp,
      email: email,
    };

    const response = await fetch(`${restEndpoint}inquiry/verify`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(formData), // body data type must match "Content-Type" header
    });

    const dataV = await response.json();
    return dataV;
  };

  const onSubmit = async (data) => {
    try {
      const resData = await formSubmitData(data);

      if (resData) {
        setOtpVerified(resData);
        handleOnSubmitSuccess?.(resData);
      }
    } catch (error) {
      showSnackbarError(error?.message);
    }
  };

  const required = (value) => (value ? undefined : 'Required');

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce((error, validator) => error || validator(value), undefined);

  return (
    <Row
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={styles.inquiryForm}
      rowGap={20}
    >
      <Typography>Verify Email</Typography>
      <Typography variant="h5">
        {isOtpVerified
          ? 'Form Submited Sccesfully We Will Get Back To You Soon!'
          : 'OTP Has Been Sent To Your Registerd Mail ID'}
      </Typography>
      <Typography variant="small">{email}</Typography>
      {!isOtpVerified && (
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, values }) => {
            return (
              <form className={styles.inquiryForm} onSubmit={handleSubmit}>
                <Row flexDirection="column" rowGap={10} alignItems="center">
                  <InputField
                    name="otp"
                    label="Please Enter OTP"
                    type="tel"
                    validate={composeValidators(required)}
                  />

                  <Row className="buttons" justifyContent="center">
                    <Button
                      className={styles.inquiry}
                      onClick={handleSubmit}
                      isProcessing={submitting}
                    >
                      Verify OTP
                    </Button>
                  </Row>
                </Row>
              </form>
            );
          }}
        />
      )}
    </Row>
  );
};

export default OtpVerify;
