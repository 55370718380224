import React from 'react';
import Icon from '@CommonUI/icon/Icon';
import { Row } from '@CommonUI/layout';
import { bool, node, oneOfType, shape, string } from 'prop-types';
import { useModalContext } from '../ModalContext';
import styles from './ModalHead.module.scss';

/**
 * Modal Head Component
 * @param {children} children - Child Component
 * @param {string} className - Class to override styles
 * @param {object} iconProps - Props for icon component
 * @param {string} justifyContent - Prop to justify modal title
 * @param {boolean} withCloseIcon - Flag to show/hide close icon
 * @returns {*}
 * @constructor
 */

const ModalHead = ({ children, iconProps, className, justifyContent, withCloseIcon }) => {
  const { closeModal } = useModalContext();

  const iconCallBack = () => {
    iconProps.onClick && iconProps.onClick();
    closeModal();
  };

  return (
    <Row justifyContent={justifyContent} className={className} flexWrap="nowrap">
      {children}
      {withCloseIcon && (
        <Icon
          {...iconProps}
          className={`${styles.closeIcon} ${iconProps.className}`}
          onClick={iconCallBack}
        />
      )}
    </Row>
  );
};

ModalHead.propTypes = {
  children: oneOfType([string, node]),
  iconProps: shape({}),
  className: string,
  justifyContent: string,
  withCloseIcon: bool,
};

ModalHead.defaultProps = {
  iconProps: {
    iconName: 'close',
  },
  className: '',
  children: '',
  justifyContent: 'space-between',
  withCloseIcon: true,
};

export default ModalHead;
