import React, { Fragment } from 'react';
import AppLayout from '../../app/appProvider/AppLayout';
import { Col, Container, Row } from '@CommonUI/layout';
import Card from '@CommonUI/card/Card';
import styles from './Home.module.scss';
import InquiryForm from '@Components/inquiryBox/inquiryForm/InquiryForm';
import { useState } from 'react';
import Typography from '@CommonUI/typography/Typography';
import card1 from '@Assets/images/card1.jpg';
import card2 from '@Assets/images/card2.jpg';
import card3 from '@Assets/images/card3.jpg';
import { Link } from 'react-router-dom';
import OtpVerify from '@Components/inquiryBox/otpVerify/OtpVerify';

const Home = () => {
  const [formSubmitSuccess, setFormSubmitSuccess] = useState('');
  const universityList = [
    { name: 'Atlantic Technological University', url: 'https://www.atu.ie/' },
    { name: 'Dublin Business School', url: 'https://www.dbs.ie/' },
    { name: 'Dublin City University', url: 'https://www.dcu.ie/' },
    { name: 'Dundalk Institute of Technology', url: 'https://www.dkit.ie/' },
    { name: 'Griffith College', url: 'https://www.griffith.ie/' },
    { name: 'Maynooth University', url: 'https://www.maynoothuniversity.ie/' },
    {
      name: 'Munster Technological University',
      url: 'Munster Technological University',
    },
    { name: 'National College of Ireland', url: 'https://www.ncirl.ie/' },
  ];

  const universityList2 = [
    { name: 'University of Galway', url: 'https://www.universityofgalway.ie/' },
    {
      name: 'South East Technological University',
      url: 'https://www.setu.ie/',
    },
    {
      name: 'Technological University Dublin',
      url: 'https://www.tudublin.ie/',
    },
    {
      name: 'Technological University of the Shannon: Midlands Midwest',
      url: 'https://www.ait.ie/',
    },
    { name: 'Trinity College Dublin', url: 'https://www.tcd.ie/' },
    { name: 'University College Cork', url: 'https://www.ucc.ie/' },
    { name: 'University College Dublin', url: 'https://www.ucd.ie/' },
    { name: 'University of Limerick', url: 'https://www.ul.ie/' },
  ];

  const venueList = [
    {
      id: '001',
      city: 'Delhi',
      location: 'The LaLit New Delhi',
      date: 'Sep 30',
      time: '12 PM to 4PM',
    },
    {
      id: '002',
      city: 'Pune',
      location: 'Hyatt Regency',
      date: 'Oct 1',
      time: '12 PM to 4PM',
    },
    {
      id: '003',
      city: 'Mumbai',
      location: 'The St.Regis',
      date: 'Oct 4',
      time: '1 PM to 5PM',
    },
    {
      id: '004',
      city: 'Chennai',
      location: 'Taj Coromandel',
      date: 'Oct 7',
      time: '12 PM to 4PM',
    },
    {
      id: '005',
      city: 'Bengaluru',
      location: 'Taj MG Road',
      date: 'Oct 8',
      time: '11 AM to 3PM',
    },
  ];
  return (
    <AppLayout>
      <Row className={styles.home} justifyContent="center" alignItem="center" alignContent="center">
        <Container className={styles.homeCard}>
          <Row className={`${styles.event}`} justifyContent="space-between" rowGap={20}>
            <Col md={6} className={styles.venue}>
              <Row
                justifyContent="center"
                alignContent="left"
                className={styles.venueContent}
                flexDirection="column"
              >
                <Typography className={styles.venueContentTitle} variant="h1">
                  <span className={styles.venueContentTitleCountry}>Ireland</span> Edu Fair 2023
                </Typography>
                {/* <Typography className={styles.venueContentTitle2} variant="h1">
                  
                </Typography> */}
                <Row className={styles.venueContentList}>
                  {venueList.map((item) => {
                    return (
                      <Row
                        className={styles.venuePlace}
                        justifyContent="space-around"
                        flexDirection="row"
                      >
                        <Col xs={3} className={styles.venueLocation}>
                          <Typography variant="p">{item.city}</Typography>
                        </Col>
                        <Col xs={4} className={styles.venueHotelname}>
                          <Typography variant="p">{item.location}:</Typography>
                        </Col>
                        <Col xs={5} className={styles.venueDatetime}>
                          <Typography variant="p">
                            {item.date} - {item.time}
                          </Typography>
                        </Col>
                        {/* <Col>
                          <Row flexDirection="column">
                            <span>{item.location}</span>
                            <span>
                            {item.date} - {item.time}
                            </span>
                          </Row>
                        </Col> */}
                      </Row>
                    );
                  })}
                </Row>
                <Card theme="natural" className={styles.venueCard}>
                  <span>
                    Attend the fair and get scholarships <span> Upto 5000 </span>
                    Euros
                  </span>
                </Card>
                <Card theme="irish" className={styles.venueCard2}>
                  <span>The Next Gen UG, PG and PHD Programmes</span>
                </Card>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                {formSubmitSuccess ? (
                  <Row
                    className={styles.home}
                    justifyContent="center"
                    alignItem="center"
                    alignContent="center"
                    flexDirection="column"
                  >
                    <OtpVerify email={formSubmitSuccess} />
                  </Row>
                ) : (
                  <InquiryForm
                    handleOnSubmitSuccess={(response) => {
                      setFormSubmitSuccess(response.email);
                    }}
                  />
                )}
              </Row>
            </Col>
          </Row>
        </Container>
        <Row className={styles.junction} justifyContent="space-between">
          <Col>
            <Row
              flexDirection="column"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <img src={card1} />
              <span>Participating Institutions</span>
            </Row>
          </Col>
          <Col>
            <Row
              flexDirection="column"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <img src={card2} />
              <span>Featured Courses</span>
            </Row>
          </Col>
          <Col>
            <Row
              flexDirection="column"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <img src={card3} />
              <span>Live Chat Now with Expert Counselors</span>
            </Row>
          </Col>
        </Row>
      </Row>
      <Container>
        <Row className={styles.univ} justifyContent="center">
          <Typography className={styles.univTitle} variant="h2">
            Participating Institutions
          </Typography>
          <Row className={styles.univList} justifyContent="space-around" alignItems="center">
            <Col md={4}>
              {universityList.map((item) => {
                return (
                  <a href={item?.url} target="_blank" rel="noreferrer noopener">
                    <Typography className={styles.univListItem} variant="p">
                      {item?.name}
                    </Typography>
                  </a>
                );
              })}
            </Col>
            <Col md={4}>
              {universityList2.map((item) => {
                return (
                  <a href={item?.url} target="_blank" rel="noreferrer noopener">
                    <Typography className={styles.univListItem} variant="p">
                      {item?.name}
                    </Typography>
                  </a>
                );
              })}
            </Col>
          </Row>
        </Row>
      </Container>
    </AppLayout>
  );
};

export default Home;
