import React from 'react';
import Card from '@CommonUI/card/Card';
import { Col, Container, Row } from '@CommonUI/layout';
import Typography from '@CommonUI/typography/Typography';
import card1 from '@Assets/images/card1.jpg';
import card2 from '@Assets/images/card2.jpg';
import card3 from '@Assets/images/card3.jpg';
import User from '@Assets/images/svg/user.png';
import styles from './Profile.module.scss';
import ContainerFluid from '@CommonUI/layout/containerFluid/ContainerFluid';
import Leads from '../leads/Leads';

const Profile = () => {
  return (
    <ContainerFluid>
      <Row
        className={styles.profile}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        rowGap={20}
      >
        <img src={User} />
        <Typography variant="h2"> Welcome, John Doe </Typography>
        <Typography variant="p">
          Manage your info, privacy, and security to make Google work better for you. Learn more
        </Typography>
        <Row rowGap={20} columnGutter={20}>
          <Col md={6}>
            <Card className={styles.profileCard}>
              <Row flexWrap="nowrap" className={styles.profileCardContent}>
                <Row alignContent="center">
                  <Typography variant="h4"> Privacy & personalization</Typography>
                  <Typography variant="p">
                    See the data in your Google Account and choose what activity is saved to
                    personalize your Google experience
                  </Typography>
                </Row>

                <img src={card1} />
              </Row>
              <Row className={styles.profileCardFooter}>
                <Typography variant="h5">Manage your data & privacy</Typography>
              </Row>
            </Card>
          </Col>
          <Col md={6}>
            <Card className={styles.profileCard}>
              <Row flexWrap="nowrap" className={styles.profileCardContent}>
                <Row alignContent="center">
                  <Typography variant="h4">Your account is protected</Typography>
                  <Typography variant="p">
                    The Security Checkup checked your account and found no recommended actions
                  </Typography>
                </Row>
                <img src={card2} />
              </Row>
              <Row className={styles.profileCardFooter}>
                <Typography variant="h5">See Details</Typography>
              </Row>
            </Card>
          </Col>
        </Row>

        <Card className={styles.profileCard}>
          <Row flexWrap="nowrap" className={styles.profileCardContent}>
            <Row alignContent="center">
              <Typography variant="h4"> Privacy suggestions available</Typography>
              <Typography variant="p">
                Take the Privacy Checkup and choose the settings that are right for you
              </Typography>
            </Row>

            <img src={card3} />
          </Row>
          <Row className={styles.profileCardFooter}>
            <Typography variant="h5">Review suggestions (4)</Typography>
          </Row>
        </Card>

        <Leads />
      </Row>
    </ContainerFluid>
  );
};

export default Profile;
