import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import AppLayout from '../../app/appProvider/AppLayout';
import { Container } from '@CommonUI/layout';
import Typography from '@CommonUI/typography/Typography';
import styles from './InquiryList.module.scss';

const InquiryList = () => {
  const [inquiryList, setInquiryList] = useState();
  const restEndpoint = 'https://test-4z7lafv5vq-as.a.run.app/api/';

  const getInquiryList = async () => {
    try {
      const response = await fetch(`${restEndpoint}inquiry`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInquiryList().then((data) => setInquiryList(data));
  }, []);

  return (
    <AppLayout>
      <Container>
        <Typography variant="h2">List Of Student Inquiry </Typography>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Education</th>
              <th>Prefered Courses</th>
              <th>IELTS Done?</th>
              <th>IELTS Score</th>
              <th>IELTS Exam Date</th>

              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {inquiryList &&
              inquiryList?.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item?.name}</td>
                    <td>{item?.email}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.education}</td>
                    <td>{item?.preferedCource}</td>
                    <td>{item?.ieltsDone}</td>
                    <td>{item?.ieltsScore}</td>
                    <td>{item?.ieltsExamDate}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Container>
    </AppLayout>
  );
};

export default InquiryList;
