import React from 'react';
import { array, bool, func } from 'prop-types';
import Typography from '@CommonUI/typography/Typography';
import Modal from '@CommonUI/modal/Modal';
import LoginForm from '@Components/forms/loginForm/LoginForm';
import styles from './LoginModal.module.scss';

const LoginModal = ({ handleAfterClose, showModal }) => {
  return (
    <Modal classNam={styles.login} handleAfterClose={handleAfterClose} showModal={true}>
      <Modal.head className={styles.loginHeader}>
        <Typography variant="h2">LOGIN</Typography>
      </Modal.head>
      <Modal.body>
        <LoginForm />
      </Modal.body>
    </Modal>
  );
};

// Perform Prop Validation
LoginModal.propTypes = {
  handleAfterClose: func,
  showModal: bool,
  tabList: array,
  isForceLogin: bool,
  onLoginSuccess: func,
};

LoginModal.defaultProps = {
  onLoginSuccess: null,
};

export default LoginModal;
