// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectBoxField-module--error > div {\n  border-color: #ab5c0e;\n}", "",{"version":3,"sources":["webpack://./src/components/formFields/selectBoxField/SelectBoxField.module.scss","webpack://./src/assets/styles/Colors.scss"],"names":[],"mappings":"AAEA;EACE,qBCKW;ADNb","sourcesContent":["@import '@Assets/styles/Colors.scss';\n\n.error > div {\n  border-color: $error-rust;\n}\n","// Base Colors\n$navy: #1b1d1f;\n$skyBlue: #6ec5f5;\n$iceBlue: #d5f6ff;\n$green: #325a17; // Sales Funnel\n$natural: #fbf9f5;\n$white: #fff;\n$black: #000;\n$error-rust: #ab5c0e;\n$irish: #08a04b;\n\n// VIP & Personalization\n// $vip-red: #DB2840;\n$vip-red: #8c2d76;\n$asphalt: #292826;\n$overlay-dark: rgba(0, 0, 0, 0.8);\n\n// Gray Collection\n$gray: #858585;\n$very-light-gray: #c7c7c7;\n$very-light-shade-gray: #f6f6f6;\n$warm-gray: #6b6966;\n$warm-light-gray: #f3f3f2;\n$warm-medium-gray: #d8d5d2;\n$palette-grey-gallery: #ededed;\n$dim-gray: #666;\n\n$navy-dark: #003359;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "SelectBoxField-module--error"
};
export default ___CSS_LOADER_EXPORT___;
