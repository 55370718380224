import Button from '@CommonUI/button/Button';
import { Col, Row } from '@CommonUI/layout';
import Typography from '@CommonUI/typography/Typography';
import InputField from '@Components/formFields/inputFields/InputField';
import React from 'react';
import { Form } from 'react-final-form';
import LinkTag from '@CommonUI/linkTag/LinkTag';
import styles from './LoginForm.module.scss';

const LoginForm = () => {
  const onSubmit = () => {
    console.log('login');
  };
  return (
    <Row
      className={styles.login}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      rowGap={30}
    >
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form className={styles.loginForm}>
              <Row flexDirection="column" rowGap={20}>
                <InputField name="email" label="Email" autoComplete="on" />
                <InputField name="password" label="Password" autoComplete="on" />
                <Row flexDirection="row" flexWrap="nowrap">
                  <Row>
                    <Typography variant="small">Don't have an account? </Typography>
                    <LinkTag>SignUp</LinkTag>
                  </Row>
                  <Button size="small" onClick={handleSubmit}>
                    Login
                  </Button>
                </Row>
              </Row>
            </form>
          );
        }}
      />
    </Row>
  );
};

export default LoginForm;
