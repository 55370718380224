import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from '../views/admin/Dashboard';
import Home from '../views/home/Home';
import InquiryList from '../views/inquiryList/InquiryList';
import HomeV2 from '../views/home/HomeV2';
import Register from '../views/register/Register';
import EventDetails from '../views/eventDetails/EventDetails';

const RouterProvider = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeV2 />}></Route>
        <Route path="/studentlist" element={<InquiryList />} />
        <Route path="/register" element={<Register />} />
        <Route path="/event" element={<EventDetails />} />
        <Route path="dashboard" element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterProvider;
