export const typographyTypes = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "p",
  "small",
  "label",
];
