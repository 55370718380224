import React from 'react';
import watchOuterClicks from '../../hooks/WatchOuterClicks';
import { handleFocusInAndOut } from '../../utills/ADA';
import { array, bool, func, string } from 'prop-types';
import { useEffect, useState } from 'react';
// import Icon from '../icon/Icon';
import { Col, Row } from '../layout';
import styles from './Selectbox.module.scss';
// import cartDown from '../../assets/svg/icons/caret_down.svg';
// import cartDown from '../../assets/svg/icons/caret_down.svg';
/**
 * Selectbox Component
 * @param {string} id - Id tag for Selectbox
 * @param {string} name - Name for Selectbox tag
 * @param {string} className - Classname to override component styles
 * @param {string} defaultLabel - Default text to be placed on the Selectbox
 * @param {function} handleOnChange - On change callback
 * @param {array} options - List of items to be displayed
 * @returns {*}
 * @constructor
 */
const Selectbox = ({ id, name, defaultLabel, handleOnChange, options, className, isFormField }) => {
  const [dropDownList, setSropDownList] = useState();
  const [collapse, setCollapse] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState();

  const classes = [styles.selectbox, styles[`selectbox-${collapse ? 'show' : 'hide'}`], className]
    .join(' ')
    .trim();

  const handleClick = (index) => {
    // reset previously selected option
    dropDownList.forEach((item) => (item.isSelected = false));

    // make clicked option active
    dropDownList[index].isSelected = true;

    setSelectedLabel(dropDownList[index].label);
    handleOnChange?.(dropDownList[index].value);
  };

  useEffect(() => {
    setSropDownList(options);
  }, [options, setSropDownList]);

  const handleOuterClick = () => setCollapse(false);

  const ref = watchOuterClicks(collapse, handleOuterClick);

  if (!dropDownList) return null;

  const selectedItem =
    options.filter((item) => item.isSelected === true)[0]?.label ?? selectedLabel;

  const isFormLabel = selectedLabel || selectedItem;

  return (
    <div
      className={classes}
      name={name}
      id={id}
      onClick={() => setCollapse(!collapse)}
      ref={ref}
      tabIndex="0"
      role="button"
      aria-expanded={collapse}
      aria-haspopup="listbox"
      onKeyUp={handleFocusInAndOut.bind(this, [
        () => setCollapse(!collapse),
        () => setCollapse(false),
      ])}
    >
      <Row className={styles.selectboxHeader} justifyContent="flex-end" alignItems="center">
        <Col>
          {isFormField ? (
            <>
              <span className={isFormLabel ? styles.selectboxLabelActive : ''}>{defaultLabel}</span>
              <span className={isFormLabel ? styles.selectboxFieldActive : ''}>
                {`${selectedItem ?? ''}`.trim()}
              </span>
            </>
          ) : (
            <span>{`${defaultLabel} ${selectedItem ?? ''}`.trim()}</span>
          )}
        </Col>
        <svg
          height="10.013"
          viewBox="0 0 18.332 10.013"
          width="18.332"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m0 0 7.052 5.939 7.048-5.939"
            fill="none"
            stroke="#0f2d49"
            stroke-linecap="round"
            stroke-width="3"
            transform="translate(2.114 2.114)"
          />
        </svg>{' '}
        {/* <cartDown /> */}
        {/* <Icon className={styles.selectboxHeaderIcon} iconName="caret_down" /> */}
      </Row>
      <ul className={styles.selectboxDropdown}>
        {dropDownList?.map((item, index) => {
          return (
            <li
              key={item.label}
              className={`${styles.selectboxDropdownItem} ${
                selectedItem === item.label ? styles.selectboxDropdownItemActive : ''
              }`}
              onClick={() => handleClick(index)}
              tabIndex={collapse ? 0 : -1}
              role="option"
            >
              {item.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

// Perform Prop Validation
Selectbox.propTypes = {
  id: string,
  name: string,
  className: string,
  defaultLabel: string,
  handleOnChange: func,
  options: array,
  isFormField: bool,
};

// Declare default props
Selectbox.defaultProps = {
  id: null,
  name: null,
  className: '',
  defaultLabel: '',
  handleOnChange: null,
  options: [],
  isFormField: false,
};

// Export the component
export default Selectbox;
