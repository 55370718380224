import React from 'react';
import { any } from 'prop-types';
import { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      info: '',
    };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, info) {
    console.log(info?.componentStack);
    this.setState({ info: info });
  }

  render() {
    if (this.state.hasError) {
      return <div>Not Found</div>;
    }
    return this.props.children;
  }
}

//Props validation
ErrorBoundary.propTypes = {
  children: any,
};

export default ErrorBoundary;
