import React from "react";
import { func, node, oneOfType, string } from "prop-types";
import SnackbarProvider from "react-simple-snackbar";
import ErrorBoundary from "../../components/errorBoundary/ErrorBoundary";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const AppLayout = ({ children }) => {
  return (
    <SnackbarProvider>
      <header>
        <Header />
      </header>
      <main id="mstark-main" role="main" aria-live="polite">
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
      <footer>
        <Footer />
      </footer>
    </SnackbarProvider>
  );
};

AppLayout.propTypes = {
  children: oneOfType([string, node]).isRequired,
  customHeader: func,
  customFooter: func,
};

AppLayout.defaultProps = {
  customHeader: null,
  customFooter: null,
};

export default AppLayout;
