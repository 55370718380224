/**
 * Function - handleFocusInAndOut
 * @param {function} focusInCallback - On keyUp callback function
 * @param {function} focusOutCallback - On keyUp callback function
 * @returns {*}
 */

export const handleFocusInAndOut = ([focusInCallback, focusOutCallback], e) => {
  if (e?.key?.toLowerCase() === "enter" && e?.keyCode === 13) {
    return focusInCallback?.(e);
  } else if (e?.key?.toLowerCase() === "escape" && e?.keyCode === 27) {
    return focusOutCallback?.(e);
  }
};
