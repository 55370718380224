import React, { Fragment } from 'react';
import { useState } from 'react';
import { Form, Field } from 'react-final-form';
import Button from '@CommonUI/button/Button';
import Checkbox from '@CommonUI/checkbox/Checkbox';
import { Col, Container, Row } from '@CommonUI/layout';
import InputField from '@Components/formFields/inputFields/InputField';
import SelectField from '@Components/formFields/selectBoxField/SelectBoxField';
import styles from './InquiryForm.module.scss';
import Typography from '@CommonUI/typography/Typography';
import UseSnackbar from '@Hooks/UseSnackbar';

const InquiryForm = ({ className, handleOnSubmitSuccess }) => {
  const classes = [className, styles.inquiry].join(' ').trim();
  const [isFormSubmitEnabled, setIsFormSubmitEnabled] = useState(false);
  const [formResponseData, setFormResponse] = useState();
  const { showSnackbarError } = UseSnackbar();

  const restEndpoint = 'https://test-4z7lafv5vq-as.a.run.app/api/';

  const handleCheckBoxOnChange = (value) => {
    if (value) {
      setIsFormSubmitEnabled(true);
    } else {
      setIsFormSubmitEnabled(false);
    }
  };

  const submitFormData = async (data) => {
    const formData = {
      name: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phoneNumber,
      education: data?.education,
      preferedCource: data?.preferedCource,
      ieltsDone: data?.ieltsDone,
      ieltsScore: data?.ieltsScore,
      ieltsExamDate: data?.ieltsExamDate,
      city: data?.city,
    };

    const response = await fetch(`${restEndpoint}inquiry`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(formData), // body data type must match "Content-Type" header
    });
    const dataV = await response.json();
    console.log(dataV);
    return dataV;
  };
  const onSubmit = async (inputdata) => {
    try {
      const resData = await submitFormData(inputdata);
      if (resData) {
        setFormResponse(resData);
        handleOnSubmitSuccess?.(resData);
      }
    } catch (error) {
      console.log(error);
      showSnackbarError(error);
    }
  };

  const PHONE_NUMBER = /^[0-9 ()-]+$/;
  const EMAIL = /^\S+@\S+\.\S+$/;

  const required = (value) => (value ? undefined : 'Required');
  const mustBeNumber = (value) => (isNaN(value) ? 'Must be a number' : undefined);
  const phoneNumber = (value) => {
    return !value || (PHONE_NUMBER.test(value) && value?.length === 10)
      ? undefined
      : `Please enter a valid 10-digit Phone Number`;
  };
  const email = (value) =>
    !value || EMAIL.test(value) ? undefined : `Please enter email in valid format`;

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce((error, validator) => error || validator(value), undefined);

  return (
    <Row
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={styles.inquiry}
    >
      <Row
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        flexDirection="column"
        className={styles.inquiryHead}
      >
        <Typography variant="h2" className={styles.inquiryHeadTitle}>
          REGISTRATION
        </Typography>
      </Row>
      <Container>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, values }) => {
            const { ieltsDone } = values;
            return (
              <form className={styles.inquiryForm} onSubmit={handleSubmit}>
                <Row flexDirection="column" rowGap={20}>
                  <Typography className={styles.inquiryFormHead} variant="h4">
                    About You
                  </Typography>

                  <Row flexDirection="row" columnGutter={10} rowGap={20}>
                    <Col md={6}>
                      <SelectField
                        name="city"
                        defaultLabel="Which City you are going to?"
                        isFormField={true}
                        label="City"
                        validate={required}
                        options={[
                          {
                            label: 'Chennai',
                            value: 'Chennai',
                          },
                          {
                            label: 'Bangaluru',
                            value: 'bangaluru',
                          },
                          {
                            label: 'Mumbai',
                            value: 'Mumbai',
                          },
                          {
                            label: 'Pune',
                            value: 'Pune',
                          },
                          {
                            label: 'Delhi',
                            value: 'Delhi',
                          },
                        ]}
                      />
                    </Col>
                    <Col md={6}>
                      <Row flexDirection="row" columnGutter={10}>
                        <Col xs={6}>
                          <InputField
                            name="firstName"
                            label="First Name"
                            autoComplete="on"
                            validate={required}
                          />
                        </Col>
                        <Col xs={6}>
                          <InputField
                            name="lastName"
                            label="Last Name"
                            autoComplete="on"
                            validate={required}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <InputField
                    name="phoneNumber"
                    label="Phone Number"
                    autoComplete="on"
                    type="tel"
                    validate={composeValidators(required, mustBeNumber, phoneNumber)}
                  />
                  <InputField
                    name="email"
                    label="Email"
                    type="email"
                    validate={composeValidators(required, email)}
                  />
                  <Typography className={styles.inquiryFormHead} variant="h4">
                    What are you looking for?
                  </Typography>
                  <SelectField
                    name="education"
                    defaultLabel="Current Grade"
                    isFormField={true}
                    label="Current Grade"
                    validate={required}
                    options={[
                      {
                        label: 'Undergraduate',
                        value: 'Undergraduate',
                      },
                      {
                        label: 'Postgraduate',
                        value: 'Postgraduate',
                      },
                      {
                        label: 'PHD',
                        value: 'PHD',
                      },
                      {
                        label: 'Working',
                        value: 'Working',
                      },
                    ]}
                  />
                  <InputField
                    name="preferedCource"
                    label="Course of Interest"
                    type="text"
                    validate={required}
                  />
                  <SelectField
                    name="ieltsDone"
                    defaultLabel="Have You Given IELTS?"
                    isFormField={true}
                    label="Have You given IELTS?"
                    validate={required}
                    options={[
                      {
                        label: 'Yes',
                        value: 'Yes',
                      },
                      {
                        label: 'No',
                        value: 'No',
                      },
                    ]}
                  />
                  {ieltsDone && ieltsDone === 'Yes' && (
                    <SelectField
                      name="ieltsScore"
                      defaultLabel="IELTS Score"
                      type="text"
                      isFormField={true}
                      label="IELTS Score"
                      validate={required}
                      options={[
                        {
                          label: '4.5',
                          value: '4.5',
                        },
                        {
                          label: '5',
                          value: '5',
                        },
                        {
                          label: '5.5',
                          value: '5.5',
                        },
                        {
                          label: '6',
                          value: '6',
                        },
                        {
                          label: '6.5',
                          value: '6.5',
                        },
                        {
                          label: '7',
                          value: '7',
                        },
                        {
                          label: '7.5',
                          value: '7.5',
                        },
                        {
                          label: '8',
                          value: '8',
                        },
                        {
                          label: '8.5',
                          value: '8.5',
                        },
                        {
                          label: '9',
                          value: '9',
                        },
                      ]}
                    />
                  )}
                  {ieltsDone && ieltsDone === 'No' && (
                    <Fragment>
                      <Typography variant="small">
                        When are you planning to give? Please specify tentative date in below box.
                      </Typography>
                      <InputField
                        name="ieltsExamDate"
                        label="(Ex: 21-Dec-2022)"
                        type="text"
                        validate={required}
                      />
                    </Fragment>
                  )}
                  <Checkbox
                    isChecked={isFormSubmitEnabled}
                    handleOnChange={handleCheckBoxOnChange}
                    value="enableFormSubmit"
                    label="*I agree to all the T&C of Apply Ireland"
                  />
                  <Row className="buttons">
                    <Button
                      theme="navy"
                      disabled={!isFormSubmitEnabled}
                      className={styles.inquiryBtnSubmit}
                      onClick={handleSubmit}
                      isProcessing={submitting}
                    >
                      SUBMIT
                    </Button>
                  </Row>
                </Row>
              </form>
            );
          }}
        />
      </Container>
    </Row>
  );
};

export default InquiryForm;
