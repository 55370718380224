// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoginForm-module--login {\n  padding: 30px;\n}\n.LoginForm-module--login-form {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/forms/loginForm/LoginForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,WAAA;AACJ","sourcesContent":[".login {\n  padding: 30px;\n\n  &-form {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": "LoginForm-module--login",
	"login-form": "LoginForm-module--login-form",
	"loginForm": "LoginForm-module--login-form"
};
export default ___CSS_LOADER_EXPORT___;
