import React from 'react';
import AppLayout from '../../app/appProvider/AppLayout';
import { Col, Container, Row } from '@CommonUI/layout';
import Typography from '@CommonUI/typography/Typography';
import Leads from '@Components/dashBoard/leads/Leads';
import SideNav from '@Components/sideNavbar/SideNav';
import User from '../../assets/images/svg/user.png';
import styles from './Dashboard.module.scss';
import Card from '@CommonUI/card/Card';
import Profile from '@Components/dashBoard/profile/Profile';

const Dashboard = () => {
  return (
    <AppLayout>
      {/* <Container> */}
      <Row>
        <Col md={3}>
          <SideNav />
        </Col>
        <Col className={styles.dashboard} md={9}>
          <Profile />
        </Col>
      </Row>

      {/* </Container> */}
    </AppLayout>
  );
};

export default Dashboard;
