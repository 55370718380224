import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from '@CommonUI/layout';
import styles from './Header.module.scss';
import logo from '../../assets/images/logo.jpeg';
import LoginModal from '@Components/loginModal/LoginModal';
import useLoginModal from '@Hooks/UseLoginModal';

const Header = () => {
  const { showLoginModal, isLoginModalActive, hideLoginModal } = useLoginModal();

  return (
    <Container className={styles.header}>
      {/* <Row className={styles.headerTopMenu} justifyContent="flex-end">
        <div className={styles.headerTopMenuItems}>
          <a>Create User</a>
          <a onClick={showLoginModal}>Login</a>
        </div>
      </Row> */}
      <Row className={styles.headerWrapper} justifyContent="space-between" alignItems="center">
        <Col noflex>
          <Link to="/">
            <img className={styles.headerLogo} src={logo} />
          </Link>
        </Col>
        <Col noflex>
          <Row display="flex" justifyContent="space-between">
            <div className={styles.headerNav}>
              <Link onClick={showLoginModal} className={styles.headerNavLink} to="/">
                Login
              </Link>
              {/* <Link className={`${styles.headerNavLink}`} to="/Home2">
                Home2
              </Link>
              <Link className={`${styles.headerNavLink}`} to="/students">
                Students
              </Link> */}
            </div>
          </Row>
        </Col>
      </Row>
      {isLoginModalActive && <LoginModal handleAfterClose={hideLoginModal} />}
    </Container>
  );
};

export default Header;
