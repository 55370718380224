// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalHead-module--close-icon {\n  position: absolute;\n  top: 20px;\n  right: 15px;\n  width: 25px;\n}\n.ModalHead-module--close-icon path {\n  stroke-width: 2px;\n}", "",{"version":3,"sources":["webpack://./src/commonUI/modal/modalHead/ModalHead.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;AACF;AACE;EACE,iBAAA;AACJ","sourcesContent":[".close-icon {\n  position: absolute;\n  top: 20px;\n  right: 15px;\n  width: 25px;\n\n  path {\n    stroke-width: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close-icon": "ModalHead-module--close-icon",
	"closeIcon": "ModalHead-module--close-icon"
};
export default ___CSS_LOADER_EXPORT___;
